.footer {
  float: left;
  width: 100%;

  h3 {
    margin: 0
  }

  .secarea {
    float: left;
    width: 100%;
    padding: 75px 0px 35px 0px;
    background: #003972;

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      // align-items: center;
      .one_fourth {
        width: 21%;
        position: relative;
        margin-right: 4%;
        float: left;

        aside.widget {
          display: inline-block;
          margin-bottom: 25px;

          h3.widget-title {
            margin-bottom: 14px;
            color: #fff;
            font-size: 20px;
            line-height: 25px;
            font-style: normal;
            text-transform: uppercase;
          }

          h3.widget-item-title {
            font-size: 16px;
            color: #ddd;
          }

          .execphpwidget {
            float: left;
            width: 100%;

            ul.foolist {
              float: left;
              width: 100%;
              padding: 0px;
              margin: 0px;

              li {
                padding: 4px 0px;
                margin: 0px;
                color: #b5b5b5;
                transition: all 0.3s ease;
                list-style: none;

                a {
                  color: #b5b5b5;
                }

                &:hover,
                a:hover {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  &-mobile {
    height: 70px;
  }
}

.copyrights {
  float: left;
  width: 100%;
  background: #1c2329;
  padding: 35px 0px 30px 0px;
  color: #8e9194;
  font-size: 13px;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .one_half {
    width: 50%;
    position: relative;
    float: left;
  }

  .last {
    margin-right: 0 !important;
    clear: right;
  }

  .aliright {
    text-align: right;
  }

  a {
    color: #8e9194;
    padding: 0px 5px;
    transition: all 0.3s ease;
  }
}

.contact {
  position: fixed;
  bottom: 10%;
  right: 5%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 15px;

  .contact-img {
    max-width: 40px;
    max-height: 40px;
  }

  .float-hidden-btn {
    position: absolute;
    top: -15px;
    right: -10px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.hidden-floatbtn {
  transform: translateX(50vw);
  transition: 1s;
}

.show-floatbtn {
  transform: translateX(0);
  transition: 0.5s;
}

.float-show-btn {
  position: fixed;
  bottom: 15%;
  right: 5%;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: var(--float-btn-color);
  border-radius: 100%;
  box-shadow: -1px 3px 6px 1px #0000005e;
  z-index: 100;

  svg {
    width: 45px;
    height: 45px;
    padding: 10px;
  }
}

.store {
  position: absolute;
  top: 39px;
  left: 180px;

  .style_app {
    position: absolute;
    top: 150px;
    left: -5px;
  }
}

@media (max-width: 994px) {

  .copyrights,
  .footer {
    display: none;
  }

  .contact {
    bottom: 15%;
  }
}

@media (max-width: 1220px) {
  .store {
    position: unset;

    .style_app {
      position: unset;
      margin-left: -5px;
    }
  }
  .widget-title {
      width: 215px;
  }
}

.social {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.vertical-rule {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(203, 209, 214, 1);
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

.QRApp {
  margin-bottom: -12px;
}
.mt-12px {
  margin-top: 12px;
}

.cursor-pointer {
  cursor: pointer;
}
